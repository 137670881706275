<template>
	<div class="page-container">
		<nav-bar title="考核结果" id="navbar"></nav-bar>
		<van-cell-group title="基本信息" inset>
			<van-cell title="姓名" :value="studentName" />
			<van-cell title="身份证号" :value="certNum" />
			<van-cell title="合格证数" :value="totalCount" />
		</van-cell-group>

		<van-cell-group :title="item.planName" inset v-for="(item, index) of studyList" :key="index">
			<van-cell title="年度" :value="item.year" />
			<van-cell title="成绩" :value="item.score" v-if="cityCode!=='152900' && cityCode!=='320400' && cityCode.substring(0,2)!=='21'" />
			<van-cell title="开始时间" :value="item.beginDate" />
			<van-cell title="结束时间" :value="item.endDate" />
			<van-cell title="获取合格证链接" is-link value="点击复制"
				@click="copyUrl(item)"
				v-clipboard:copy="certificateUrl"
				v-clipboard:success="onCopy"
				v-clipboard:error="onError" style="color: #0064C8"/>
			<van-cell title="下载合格证" is-link value="点击下载" @click="getCertDownloadUrl(item)" style="color: #0064C8;" />
		</van-cell-group>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '加载中...' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import { NavBar ,Toast} from "vant";
	import { getStudyCertList, getCertDownloadUrl } from "../api/user";
    export default {
        name: "study-certificate-list",
		components: { NavBar },
		data() {
        	return {
				studentName: '',
				certNum: '',
				cityCode: '',
				certificateUrl:'',
				totalCount: 0,
				studyList: [],
				showLoading: false,
			}
		},
		mounted() {
			this.getStudyCertList();
		},
		methods: {
			getStudyCertList() {
				getStudyCertList(this.$route.params.id)
					.then((res) => {
						if(res) {
							this.studentName = res.studentName;
							this.certNum = res.certNum;
							this.cityCode = res.cityCode;
							this.totalCount = res.signUpRecord.length;
							this.studyList = res.signUpRecord;
						}
					})
					.catch(() => {});
			},
			getCertDownloadUrl(obj) {
				this.showLoading = true;
				getCertDownloadUrl(obj.planId, obj.certificateId).then((res) => {
					this.showLoading = false;
					if(res) {
						window.location.href = res.url;
					}
				}).catch(() => {
					this.showLoading = false;
				});
			},
			copyUrl(obj){
				this.showLoading = true;
				getCertDownloadUrl(obj.planId, obj.certificateId).then((res) => {
					this.showLoading = false;
					if(res) {
						this.certificateUrl = res.url;
						console.log("1",res.url)
					}
				});
			},
			// 复制成功时的回调函数
			onCopy() {Toast.fail("复制成功");},
			// 复制失败时的回调函数
			onError() {Toast.fail("抱歉，复制失败！"); console.log("2",this.certificateUrl)},
			download(obj) {
				window.location.href = "/renren-fast/app/exp/download/" + obj.planId + "/" + obj.certificateId;
			}
		}
    }
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}
	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}
	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}
	.checked-area {
		text-align: center;
		margin-top: 15px;
	}
	.checked-area div span {
		font-size: 14px;
		color: #67c23a;
	}
	.share-tips {
		width: 100%;
		height: 100%;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.2);
	}
	.share-tips img {
		width: 90%
	}
	.van-cell-group__title {
		color: #409eff;
		font-weight: bold;
	}
</style>
